import { roles } from 'common-src/models/Client';

import { TaskType } from 'src/components/widgets/AwsConnectDialer/constants';

export const DispositionOptions = Object.freeze({
  [roles.MXSpecialist.id]: {
    isDismissable: false,
    callOptions: [
      'Connected & Completed',
      'Connected & Unable to Complete',
      'Left Voicemail',
      'Not connected & Unable to Leave Voicemail',
    ],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.RN.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.CDE.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.Physician.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.AccountabilityCoach.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.RD.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.NP.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
  [roles.SocialWorker.id]: {
    isDismissable: true,
    callOptions: ['Missed Visit - 1st Attempt', 'Missed Visit - 2nd Attempt', 'Completed Visit'],
    taskOptions: {
      [TaskType.BgmHyperAlert]: ['Hyper Alert Completed', 'Hyper Alert Call Attempt'],
      [TaskType.BgmHypoAlert]: ['Hypo Alert Completed', 'Hypo Alert 1st Call Attempt'],
      [TaskType.BgmHypoAlert2]: ['Hypo Alert Completed', 'Alert 2nd Call Attempt'],
      [TaskType.BgmHypoAlert3]: ['Hypo Alert Completed', 'Alert 3rd Call Attempt'],
    },
  },
});
