/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CommonIcons } from 'common-src/assets/Icons';
import { Menu } from 'common-src/components/base';
import { deleteOrmItem } from 'common-src/features/rest';
import PatientInteraction, {
  deleteDraftApiRequest,
  getDocumentationById,
  getModuleNamesByIds,
  InteractionStatus,
  NotTrackableDocumentations,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';

import { SeparatorLine } from 'src/components/elements';
import { savePatientNote } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './DraftRow.module.scss';

const DraftRow = ({ date, draftId, callback, patientId, moduleTypeIds, showSeparator = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const draft = useCustomSelector((state) => getDocumentationById(state, draftId));

  const containerClasses = [styles.container, 'p-r-16'];

  const editHandler = () => {
    if (!_.isEmpty(_.intersection(NotTrackableDocumentations, draft.moduleTypes))) {
      const newWindow = window.open(
        `/dashboard-client/documentation/details/${patientId}/${draftId}/edit`,
        '',
        'width=1200,height=800,left=300,top=100',
      );
      newWindow.draftId = draftId;
      newWindow.typeId = moduleTypeIds[0];
    } else {
      dispatch(
        updatePatientDraftApiRequest(
          draft.id,
          {
            draftData: draft.draftData,
            moduleTypes: draft.moduleTypes,
            primaryModuleTypeId: draft.primaryModuleTypeId,
            serviceAt: draft.serviceAt,
            status: InteractionStatus.Active,
          },
          {
            successBlock: () => {
              navigate(`/dashboard-client/patients/details/${patientId}/note/${draftId}/edit`);
              dispatch(savePatientNote(patientId, draftId));
            },
          },
        ),
      );
    }

    callback?.();
  };

  const deleteHandler = () =>
    dispatch(
      deleteDraftApiRequest(draftId, {
        successBlock: () => {
          deleteOrmItem(PatientInteraction.modelName, draftId);
        },
      }),
    );

  const menuItems = _.compact([
    {
      id: 'edit-draft',
      text: 'Edit draft',
      onClick: async () => editHandler(),
    },
    {
      id: 'delete-draft',
      text: 'Delete draft',
      onClick: async () => deleteHandler(),
    },
  ]);

  return (
    <>
      <div id={draftId} className={containerClasses.join(' ')} onClick={editHandler}>
        <p className={[styles.reason, 'vertically-centered', 'font-s-14'].join(' ')}>
          Note reason: {getModuleNamesByIds(moduleTypeIds).join(', ')}
        </p>
        <div className={[styles.aside, 'vertically-centered', 'font-s-14'].join(' ')}>
          <span>Draft last saved {moment(date).format('MM/DD/YYYY')}</span>
          <Menu
            button={<img src={CommonIcons.moreVertIcon} alt="more-vert-icon" className="m-l-30" />}
            options={menuItems}
          />
        </div>
      </div>
      {showSeparator && <SeparatorLine classNames={[styles.separator]} />}
    </>
  );
};

DraftRow.propTypes = {
  date: PropTypes.string,
  callback: PropTypes.func,
  draftId: PropTypes.string,
  showSeparator: PropTypes.bool,
  patientId: PropTypes.number,
  moduleTypeIds: PropTypes.arrayOf(PropTypes.number),
};

export default DraftRow;
