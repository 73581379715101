import _ from 'lodash';
import React, { useState } from 'react';

import { Button, Checkbox, Menu } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { apiRequest, HttpMethods } from 'common-src/features/rest';
import { ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useQuickSettings from 'src/hooks/useQuickSettings';

import styles from './QuickSettingsButton.module.scss';

const QuickSettingsButton = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [disabled, setDisabled] = useState({ availableForAlerts: false });

  const quickSettings =
    useCustomSelector((state) => getRoleConfig(state)?.patientChart?.quickSettings) || {};

  const { isRequesting, settings, setSettings, currentUserId } = useQuickSettings();
  const { showAlert, AlertType } = useAlert();

  if (isRequesting || _.isEmpty(quickSettings)) {
    return null;
  }

  const options = _.compact([
    quickSettings.canSetAvailableForAlerts && {
      label: (
        <div
          id="available-for-alerts-option"
          onClick={(e) => e.stopPropagation()}
          role="presentation"
          style={{ cursor: disabled.availableForAlerts ? 'not-allowed' : 'pointer' }}
        >
          <Checkbox
            id="available-for-alerts-checkbox"
            classNames={['p-12']}
            label="Available For Alerts"
            checked={settings.availableForAlerts}
            disabled={disabled.availableForAlerts}
            onChange={(checked) => {
              setDisabled((prev) => ({ ...prev, availableForAlerts: true }));
              apiRequest({
                endpoint: `clients/${currentUserId}/awscSettings/`,
                method: HttpMethods.Post,
                body: { availableForAlerts: checked },
              })
                .then((res) => res.json())
                .then((data) => {
                  setDisabled((prev) => ({ ...prev, availableForAlerts: false }));

                  if (data?.detail) {
                    showAlert(AlertType.Error, '', data.detail);
                    return;
                  }

                  setSettings((prev) => ({ ...prev, availableForAlerts: data.availableForAlerts }));
                  showAlert(AlertType.Success, '', 'Successfully updated quick settings');
                });
            }}
          />
        </div>
      ),
    },
  ]);

  return (
    <div className={styles.container}>
      <Menu
        id="quick-settings-menu"
        button={
          <Button
            id="quick-settings-button"
            classNames={[styles.button]}
            iconSrc={Icons.boldGearIcon}
            text="Quick settings"
            onClick={() => setIsMenuOpened(true)}
            textColor={ColorsNew.darkGreen}
            backgroundColor={isMenuOpened ? ColorsNew.lighterGreen : 'transparent'}
            borderColor={isMenuOpened ? ColorsNew.lighterGreen : 'transparent'}
            width={105}
          />
        }
        customOptions={options}
        paperWidth={240}
        onCloseMenu={() => setIsMenuOpened(false)}
      />
    </div>
  );
};

export default QuickSettingsButton;
