/* eslint-disable no-bitwise */
/* eslint-disable no-multi-assign */
import _ from 'lodash';

export const titleizeAlt = (s) =>
  // s
  //   .replace(/([A-Z])/g, ' $1')
  //   .replace(/^./, str => str.toUpperCase())
  //   .trim()
  //   .replace(/\s+/g, ' ')
  _.startCase(_.camelCase(s))
    .replace(/and /gi, 'and ')
    .replace(/the /gi, 'the ')
    .replace(/or /gi, 'or ');

export const bolderize = (char) => {
  let diff = '𝗮'.codePointAt(0) - 'a'.codePointAt(0);

  if (/\d/.test(char)) {
    return char.replace(/\d/g, (c) => String.fromCodePoint(0x1d79e + c.charCodeAt(0)));
  }

  if (/[A-Z]/.test(char)) {
    diff = '𝗔'.codePointAt(0) - 'A'.codePointAt(0);
    return String.fromCodePoint(char.codePointAt(0) + diff);
  }

  if (/\W/.test(char)) {
    return char;
  }

  return String.fromCodePoint(char.codePointAt(0) + diff);
};

export const capitalizeFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeFirstLetters = (string) => {
  if (typeof string !== 'string' || string.length === 0) return '';

  // Split the inputString into an array of words
  const words = string.toLowerCase().split(' ');

  // Capitalize the first letter of each word and concatenate them with spaces
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export const uuidv4 = () => {
  const uuid = new Array(36);
  for (let i = 0; i < 36; i++) {
    uuid[i] = Math.floor(Math.random() * 16);
  }
  uuid[14] = 4; // set bits 12-15 of time-high-and-version to 0100
  uuid[19] = uuid[19] &= ~(1 << 2); // set bit 6 of clock-seq-and-reserved to zero
  uuid[19] = uuid[19] |= 1 << 3; // set bit 7 of clock-seq-and-reserved to one
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  return uuid.map((x) => x.toString(16)).join('');
};

export const pluralize = (count, word) => {
  const suffix = count === 1 ? '' : 's';
  return `${count} ${word}${suffix}`;
};
