import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createPatientInteractionApiRequest } from 'common-src/models/PatientInteraction';
import { uuidv4 } from 'common-src/utils/stringUtils';

import { ModalType } from 'src/components/base/ModalGroup';
import { openModal } from 'src/features/modals';

const useNoteCreation = (patient) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createNote = useCallback(
    (typeId, reasonId, moduleTypes = []) => {
      if (!patient?.id) return;

      const createNoteRequest = () => {
        const documentationId = uuidv4();
        dispatch(
          createPatientInteractionApiRequest({
            body: {
              patientId: patient.id,
              id: documentationId,
              moduleTypes,
              type: typeId,
              reason: reasonId,
            },
            successBlock: () => {
              navigate(
                `/dashboard-client/patients/details/${patient.id}/note/${documentationId}/edit`,
              );
            },
          }),
        );
      };

      dispatch(
        openModal(ModalType.ADD_NOTE, {
          patientId: patient.id,
          callback: () => createNoteRequest(),
        }),
      );
    },
    [patient?.id],
  );

  return { createNote };
};

export default useNoteCreation;
