import { CommonIcons } from 'common-src/assets/Icons';
import { ColorsNew } from 'common-src/styles';

export const OncehubBookingStatuses = Object.freeze({
  today: {
    text: 'TODAY',
    value: 'today',
    label: 'TODAY',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  scheduled: {
    text: 'Scheduled',
    value: 'scheduled',
    label: 'Scheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  rescheduled: {
    text: 'Rescheduled',
    value: 'rescheduled',
    label: 'Rescheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.rescheduledIcon,
  },
  completed: {
    text: 'Completed',
    value: 'completed',
    label: 'Completed',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.callCheckIcon,
  },
  canceled: {
    text: 'Canceled',
    value: 'canceled',
    label: 'Canceled',
    textColor: '#F06C67',
    iconSrc: CommonIcons.calendarXIcon,
  },
  no_show: {
    text: 'No-Show',
    value: 'no_show',
    label: 'No-Show',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.calendarCheckIcon,
  },
  missed: {
    text: 'Missed',
    value: 'missed',
    label: 'Missed',
    textColor: '#F06C67',
    iconSrc: CommonIcons.calendarXIcon,
  },
  canceled_then_rescheduled: {
    text: 'Canceled Then Rescheduled',
    value: 'canceled_then_rescheduled',
    label: 'Canceled Then Rescheduled',
    textColor: ColorsNew.darkGreen,
    iconSrc: CommonIcons.rescheduledIcon,
  },
});

export const LinkType = Object.freeze({
  Oncehub: 'Oncehub',
  Jotform: 'Jotform',
});

// Reference: https://docs.google.com/spreadsheets/d/1IaEfZaZe5FIwmhgd9dStSmUw3yB_QMyY1374S7dX7fQ/edit#gid=853599734
export const ScheduleVisitLinks = {
  AccountabilityCoach: {
    label: 'AC Monthly Wellness Visit',
    value: 'https://go.oncehub.com/ACMonthlyVisit',
    linkType: LinkType.Oncehub,
  },
  CGMQualification: {
    label: 'CGM Consultation',
    value: 'https://go.oncehub.com/PerryCGMQualification',
    linkType: LinkType.Oncehub,
  },
  DeviceTraining: {
    label: 'Device Training',
    value: 'https://form.jotform.com/232553373723052',
    linkType: LinkType.Jotform,
  },
  EducationSession: {
    label: 'Education Session',
    value: 'https://go.oncehub.com/perryEDU',
    linkType: LinkType.Oncehub,
  },
  ClinicalInitialVisit: {
    label: 'First Visit',
    value: 'https://go.oncehub.com/perryCFV',
    linkType: LinkType.Oncehub,
  },
  InitialCoachingCall: {
    label: 'Initial Coaching Call',
    value: 'https://go.oncehub.com/InitialCoachingCall',
    linkType: LinkType.Oncehub,
  },
  Intake: {
    label: 'Intake',
    value: 'https://go.oncehub.com/perryhealth',
    linkType: LinkType.Oncehub,
  },
  MedicationReconciliation: {
    label: 'Medication Reconciliation',
    value: 'https://go.oncehub.com/perrymedreview',
    linkType: LinkType.Oncehub,
  },
  NutritionConsult: {
    label: 'MNT Consult',
    value: 'https://go.oncehub.com/perryMNT',
    linkType: LinkType.Oncehub,
  },
  MonthlyWellnessVisit: {
    label: 'Monthly Wellness Visit',
    value: 'https://go.oncehub.com/perryMWV',
    linkType: LinkType.Oncehub,
  },
  PersonalBookingPages: {
    label: 'Personal Booking Pages',
    value: 'https://go.oncehub.com/perrypersonal',
    linkType: LinkType.Oncehub,
  },
  BiannualVisit: {
    label: 'Biannual Visit',
    value: 'https://go.oncehub.com/PerryBiAnnualVisit',
    linkType: LinkType.Oncehub,
  },
  InsulinPumpTraining: {
    label: 'Insulin Pump Training',
    value: 'https://go.oncehub.com/PerryInsulinPump',
    linkType: LinkType.Oncehub,
  },
};

export const FilterTypeOptions = [
  'Monthly Wellness',
  'Onboarding',
  'Dietary and Nutritional Consult',
  'Dietary and Nutritional Follow-Up',
  'Device Training',
  'Medication Reconciliation',
  'Quarterly Check-In',
  'Clinical First Visit',
  'Education Session',
  'Onboarding Intake Call',
  '15-minute meeting',
  '30-minute meeting',
  '45-minute meeting',
  'Weekly 30 Minute',
  'Wellness Visit',
  'Initial Coaching Call',
];

export const VisitType = {
  Unknown: 1,
  ClinicalFirstVisit: 2,
  InitialCoachingCall: 3,
  MonthlyWellnessVisit: 4,
  NutritionMedicationAssessment: 5,
  MedicationReconciliation: 6,
  QuarterlyCheckIn: 7,
  ClinicalMeeting: 8,
  CGMQualificationVisit: 9,
  BiannualVisit: 10,
  InsulinPumpTrainingVisit: 11,
  BGMDeviceTrainingVisit: 12,
  CGMDeviceTrainingVisit: 13,
};
