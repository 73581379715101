/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CircleLoader } from 'common-src/components/base';
import { ColorsNew } from 'common-src/styles';

import { saveNoteData, saveNoteScroll, savePatientNote } from 'src/features/tabsState';
import { clearTimer } from 'src/features/timeTracker';
import useDocumentationConfig from 'src/hooks/useDocumentationConfig';
import useDocumentationDynamicResult from 'src/hooks/useDocumentationDynamicResult';
import useDocumentationErrors from 'src/hooks/useDocumentationErrors';
import useForceSubmitNote from 'src/hooks/useForceSubmitNote';
import useNoteScroll from 'src/hooks/useNoteScroll';
import useSaveDocumentation from 'src/hooks/useSaveDocumentation';
import {
  BillingInfo,
  DocumentForm,
  EmbeddedInfo,
  NoteHeader,
  SdohForm,
  SectionAside,
  SectionFooter,
  SectionHeader,
} from 'src/pages/DocumentationIndex/components';

import styles from '../commonStyles.module.scss';

const DocumentationForm = ({ patientId, documentationId, isEmbedded, isRequestRunning }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClose = () => {
    if (isEmbedded) {
      navigate(`/dashboard-client/patients/details/${patientId}/documentation`);
      dispatch(savePatientNote(patientId, null));
      dispatch(saveNoteData(patientId, {}));
      dispatch(clearTimer(patientId));
      setTimeout(() => dispatch(saveNoteScroll(patientId, 0)), 100);
    } else {
      window.close();
    }
  };

  const { scrollRef, scrollLoading, onScrollHanlder } = useNoteScroll(patientId);
  const { errors, handleError } = useDocumentationErrors();
  const {
    patient,
    documentationToEdit,
    modules,
    setModules,
    primaryModuleTypeId,
    setPrimaryModuleTypeId,
    timeLogs,
    onTimeLogChange,
    serviceAt,
    setServiceAt,
    showBillingInfo,
    setShowBillingInfo,
    regalTaskIds,
    setRegalTaskIds,
    awscCallIds,
    setAwscCallIds,
    hasRelevantConversations,
    setHasRelevantConversations,
    isEncounterNotScheduled,
    setIsEncounterNotScheduled,
    linkedVisitId,
    setLinkedVisitId,
    sdoh,
    setSdoh,
    vitalsAndLabs,
    setVitalsAndLabs,
    wellnessPlan,
    setWellnessPlan,
    cgmAlerts,
    setCgmAlerts,
    cgmEvaluation,
    setCgmEvaluation,
  } = useDocumentationConfig(patientId, documentationId);
  const {
    timer,
    savedAt,
    isDraftCompleting,
    isDraftSaving,
    hasDetectedChanges,
    setHasDetectedChanges,
    setIsServiceAtValid,
    handleBackButtonClick,
    handleButtonClick,
  } = useSaveDocumentation({
    handleError,
    modules,
    documentationToEdit,
    patientId,
    primaryModuleTypeId,
    serviceAt,
    showBillingInfo,
    setShowBillingInfo,
    timeLogs,
    regalTaskIds,
    awscCallIds,
    hasRelevantConversations,
    isEncounterNotScheduled,
    onClose,
    isEmbedded,
    linkedVisitId,
    sdoh,
    vitalsAndLabs,
    wellnessPlan,
    cgmAlerts,
    cgmEvaluation,
  });
  useDocumentationDynamicResult(modules, setModules, hasDetectedChanges);

  useForceSubmitNote(isRequestRunning);

  const onModuleSelect = (module) => {
    const updatedModules = modules.map((m) => {
      if (module.id !== m.id) return m;

      return {
        ...m,
        isSelected: !module.isSelected,
        isExpanded: !module.isExpanded,
      };
    });

    setModules(updatedModules);

    const selectedModules = updatedModules.filter((m) => m.isSelected);
    const isModuleSelected = selectedModules.find((m) => m.id === module.id);

    if (!primaryModuleTypeId && isModuleSelected) {
      setPrimaryModuleTypeId(module.moduleType);
    }
    if (!isModuleSelected) {
      !_.isEmpty(selectedModules)
        ? setPrimaryModuleTypeId(selectedModules[0].moduleType)
        : setPrimaryModuleTypeId(undefined);
    }

    setHasDetectedChanges(new Date());
  };

  const timeLogChangeHandler = (index, field, value) => {
    onTimeLogChange(index, field, value);
    setHasDetectedChanges(new Date());
  };

  const renderDocumentForm = () => (
    <DocumentForm
      patientId={patientId}
      modules={modules}
      setModules={setModules}
      showPrimaryModuleCheckbox
      primaryModuleTypeId={primaryModuleTypeId}
      setPrimaryModuleTypeId={setPrimaryModuleTypeId}
      setHasDetectedChanges={setHasDetectedChanges}
      errors={errors}
      classNames={[scrollLoading ? 'invisible' : 'visible']}
      handleError={handleError}
      vitalsAndLabs={vitalsAndLabs}
      setVitalsAndLabs={setVitalsAndLabs}
      wellnessPlan={wellnessPlan}
      setWellnessPlan={setWellnessPlan}
      cgmAlerts={cgmAlerts}
      setCgmAlerts={setCgmAlerts}
      cgmEvaluation={cgmEvaluation}
      setCgmEvaluation={setCgmEvaluation}
    />
  );

  const renderContent = () => {
    if (isEmbedded) {
      return (
        <>
          <CircleLoader
            classNames={['absolute', scrollLoading ? 'visible' : 'invisible']}
            strokeWidth={4}
            circleRadius={24}
            color={ColorsNew.darkGreen}
            style={{ bottom: '50%', left: '50%' }}
          />
          <NoteHeader
            documentation={documentationToEdit}
            patient={patient}
            serviceAt={serviceAt}
            setServiceAt={setServiceAt}
            classNames={[scrollLoading ? 'invisible' : 'visible']}
          />
          {renderDocumentForm()}
          <EmbeddedInfo
            modules={modules}
            timeLogs={timeLogs}
            onTimeLogChange={timeLogChangeHandler}
            patientId={patientId}
            hasRelevantConversations={hasRelevantConversations}
            setHasRelevantConversations={setHasRelevantConversations}
            regalTaskIds={regalTaskIds}
            setRegalTaskIds={setRegalTaskIds}
            awscCallIds={awscCallIds}
            setAwscCallIds={setAwscCallIds}
            isEncounterNotScheduled={isEncounterNotScheduled}
            setIsEncounterNotScheduled={setIsEncounterNotScheduled}
            linkedVisitId={linkedVisitId}
            setLinkedVisitId={setLinkedVisitId}
            documentationToEdit={documentationToEdit}
            classNames={[scrollLoading ? 'invisible' : 'visible']}
            errors={errors}
          />
          <SdohForm
            patientId={patientId}
            sdoh={sdoh}
            setSdoh={setSdoh}
            modules={modules}
            setModules={setModules}
            setHasDetectedChanges={setHasDetectedChanges}
            errors={errors}
            handleError={handleError}
            classNames={[scrollLoading ? 'invisible' : 'visible']}
          />
        </>
      );
    }

    if (!showBillingInfo) {
      return renderDocumentForm();
    }

    return (
      <BillingInfo
        patientId={patientId}
        timeLogs={timeLogs}
        onTimeLogChange={timeLogChangeHandler}
        serviceAt={serviceAt}
        setServiceAt={setServiceAt}
        setIsServiceAtValid={setIsServiceAtValid}
        regalTaskIds={regalTaskIds}
        setRegalTaskIds={setRegalTaskIds}
        hasRelevantConversations={hasRelevantConversations}
        setHasRelevantConversations={setHasRelevantConversations}
      />
    );
  };

  return (
    <>
      {!isEmbedded && <SectionHeader patient={patient} />}
      <section className="flex-row">
        <SectionAside
          modules={modules}
          areModulesDisabled={showBillingInfo}
          onModuleSelect={(module) => onModuleSelect(module)}
          isEmbedded={isEmbedded}
        />
        <div
          className={[styles.section, isEmbedded ? styles.isEmbedded : '', 'relative'].join(' ')}
        >
          <div ref={scrollRef} className={styles.formWrapper} onScroll={onScrollHanlder}>
            {renderContent()}
          </div>
        </div>
      </section>
      <SectionFooter
        isLoading={isDraftCompleting}
        isDraftSaving={isDraftSaving}
        isUpdating={!!hasDetectedChanges}
        draftSavedAt={savedAt}
        showBillingInfo={showBillingInfo}
        isEmbedded={isEmbedded}
        handleBackButtonClick={handleBackButtonClick}
        handleButtonClick={handleButtonClick}
        onClose={onClose}
        handleError={handleError}
        patientId={patientId}
        timer={timer}
      />
    </>
  );
};

DocumentationForm.propTypes = {
  patientId: PropTypes.number,
  documentationId: PropTypes.string,
  isEmbedded: PropTypes.bool,
  isRequestRunning: PropTypes.bool,
};

export default DocumentationForm;
