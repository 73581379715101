import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getNoteScroll, saveNoteScroll } from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

const useNoteScroll = (patientId) => {
  const scrollRef = useRef(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const currentScroll = useRef(0);

  const scroll = useCustomSelector((state) => getNoteScroll(state, patientId)) || 0;

  useEffect(() => {
    if (!scrollRef.current) return;
    setLoading(false);
  }, [scrollRef.current]);

  useEffect(() => {
    if (loading) return;
    scrollRef.current.scrollTop = scroll;
  }, [loading, scroll]);

  useEffect(
    () => () => {
      dispatch(saveNoteScroll(patientId, currentScroll.current));
    },
    [],
  );

  const onScrollHanlder = (e) => {
    currentScroll.current = e.target.scrollTop;
  };

  return { scrollRef, scrollLoading: loading, onScrollHanlder };
};

export default useNoteScroll;
